import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'

export class AttachUsersRequest {
  // Also attach existing users because this array will replace the array in the backend
  public user_ids: number[] = []

  constructor(implementation?: ImplementationResource | EntityResource) {
    this.user_ids = implementation ? implementation.team_ids : []
  }
}
